import React, { useRef } from "react";
import useIntersection from "../../../functions/useIntersection";
import { cubicBezier, motion } from "framer-motion";

export default function AnimatedText({ children, type, delay, offset, align, duration }) {

    const ref = useRef(null);
    const offsetPx = offset !== null ? offset : '-200px';
    const inViewport = useIntersection(ref, offsetPx);

    const textSrc = children;
    const headline = type == 'title' ?
        textSrc.split(" ").map((h) => {
            if (h.indexOf(" ") === 0) {
                let a = '&nbsp;'
                return a;
            } else {
                return h
            }
        })
        : type == 'paragraph' ?
            textSrc.split(" ").map((h) => `${h}&nbsp;`)
            : null;

    const easing = cubicBezier(0.34, 1.4, 0.64, 1);

    return (

        <span className={`text ${align == 'left' ? 'justify-start' : 'justify-center'} ${headline !== null ? 'overflow' : ''}`} ref={ref}>

            {
                headline !== null && inViewport ?
                    headline.map((h, i) => {
                        const delayWord = (.015 * i) + delay
                        if (type == 'title') {
                            const word = h.split("").map((a, i) => {
                                const delayCalc = (.02 * i) + delayWord
                                const letter = (i + 1) < h.split("").length ? a : `${a}&nbsp;`
                                return( 
                                    <motion.div initial={{ y: '100%', rotateX: 150  }} animate={{ y: 0, rotateX: 0 }} transition={{ duration: duration ? duration : .5, delay: delayCalc, ease: easing }} className="inline-block" dangerouslySetInnerHTML={{ __html: letter }}></motion.div>
                                )
                            })
                            return <div className="overflow inline-block relative">{word}</div>
                        }else{
                            
                            return (
                                <motion.span initial={{ y: '100%', rotateX: 90 }} animate={{ y: 0, rotateX: 0 }} transition={{ duration: duration ? duration : .5, delay: delayWord }} dangerouslySetInnerHTML={{ __html: h }}></motion.span>
                            )
                        }
                       
                    })
                    : null
            }
        </span>
    )
}
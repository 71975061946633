import { Link } from "gatsby"
import React, { useState } from "react"
import { useLogo } from "../../../hooks/useLogo"
import Container from "../../atoms/Container"
import Navigation from "../../atoms/Navigation"

const Header = () => {

  const [active, setActive] = useState(false)

  const logo = useLogo()

  const activeHandler = (e) => {
    const timer = setTimeout(() => {
      setActive(e)
    }, 0);
  }

  return (
    <header className="headerClass py-[20px]">
      {/* <Container> */}
      <div className="w-full px-[20px] mx-auto">
        <div className="flex flex-row justify-between items-center">
          <Link to={'/'} className='z-[100]' onClick={()=>{activeHandler(false)}}><img className="md:w-[236px] w-[175px]" src={logo.wp.siteLogo.localFile.publicURL} /></Link>
          <Navigation active={active}  activeHandler={(e)=>{activeHandler(e)}}/>
        </div>
      </div>
      {/* </Container> */}
    </header>
  )
}

export default Header

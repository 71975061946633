import { graphql, useStaticQuery } from "gatsby";

export const useLogo = () => {
    const data = useStaticQuery(graphql`
        query LogoQuery{
        wp {
            siteLogo {
            localFile {
                publicURL
            }
            }
        }
        }
    `)

    return data;
}

import * as React from "react";
import Cookies from 'universal-cookie';

const Cookiebar = () => {

const cookies = new Cookies();
const cookieConsentName = 'consent';

const handleCookieConsent = (consent) => {
    cookies.set(cookieConsentName, consent);
    window.location.reload(false);
}

if(cookies.get(cookieConsentName)) return null;

    return (
        <div>
            <div className="lg:flex lg:flex-row flex-wrap fixed lg:w-1/3 w-11/12 bottom-4 lg:right-12 md:right-8 right-4 bg-[#172714] px-[24px] py-[22px] text-text z-50 shadow-xl rounded-xl">
                <h3 className="text-lg pb-2">Jouw cookievoorkeuren</h3>
                <p className="text-gray">
                    Om jou een goede ervaring op onze website te geven, maken wij gebruik van cookies en vergelijkbare
                    technieken. Hierdoor kunnen wij zien hoe je onze website gebruikt en met deze gegevens maken wij onze
                    website beter en gebruiksvriendelijker.<br />
                    <button onClick={() => handleCookieConsent(true)} className="px-[24px] py-[22px] bg-secondary text-primary mt-[30px] mr-5 rounded-full cursor-pointer transition-all duration-300 border border-secondary hover:bg-primary hover:text-secondary">Cookies accepteren</button>
                    <button onClick={() => handleCookieConsent(true)} className="px-[24px] py-[22px] bg-primary text-secondary mt-[30px] rounded-full cursor-pointer transition-all duration-300 border border-secondary hover:bg-secondary hover:text-primary">Cookies weigeren</button>
                </p>
            </div>
        </div>
    )
} 

export default Cookiebar
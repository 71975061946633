import { AnimatePresence } from "framer-motion"
import * as React from "react"
import PageTransition from "../../atoms/PageTransition"
import Footer from "../../molecules/Footer"
import Header from "../../molecules/Header"
import GlobalStyles from "../GlobalStyles"

const Layout = ({ children }) => {

  return (
    <>
      <GlobalStyles />
      <Header />
      <main>
        <AnimatePresence mode="wait">
          {children}          
        </AnimatePresence>
      </main>
      <Footer />
    </>
  )
}

export default Layout

import { useState, useEffect } from "react";

export default (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setState(entry.isIntersecting);
                    observer?.unobserve(element.current);
                }
            },
            {
                rootMargin
            }
        );

        element.current && observer.observe(element.current);

        return () => {
            if (element.current && element.current instanceof Element) {
                observer.unobserve(element.current);
            } else {
                // console.error('Element not valid:', element);
            }
        };
    }, []);

    return isVisible;
};
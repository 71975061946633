import { useStaticQuery, graphql } from "gatsby"

export const useNav = () =>{
   
    const data = useStaticQuery(graphql`
    query NavQuery{
        wpMenu(locations: {eq: HEADER_MENU}) {
            menuItems {
              nodes {
                uri
                label
                nodeType
                childItems {
                  nodes {
                    uri
                    label
                    description
                  }
                }
                parentId
              }
            }
          }
    }
    `)

    return data
}
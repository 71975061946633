import { Link, navigate } from "gatsby";
import React, { useState } from "react";
import { useNav } from "../../../hooks/useNav";
import AnimatedText from "../AnimatedText";
import Button from "../Button/button";
import Container from "../Container";

export default function Navigation({active, activeHandler}){

    const data = useNav()

    const menu = data.wpMenu.menuItems.nodes.map((item, i)=>{
        return <li  key={i} onClick={()=>{activeHandler(false)}} className="lg:text-[40px] text-[28px] lg:leading-[72px] leading-[48px] text-center uppercase font-[400] lg:py-[0px] py-[5px] hover:text-[#4B4D3B] transition-all duration-300 ease-in-out"><Link to={item.uri}><AnimatedText type={'title'} delay={i * .3} duration={.5}>{item.label}</AnimatedText></Link></li>
    })

    const minusPath = 'M10.7812 10.7813C10.7812 10.7813 11.2632 10.7812 11.4538 10.7812C11.6444 10.7812 11.8693 10.7813 11.8693 10.7813C11.8693 10.7813 12 10.7813 12.2188 10.7813H16.1719H20.125C20.3156 10.7813 20.4984 10.857 20.6332 10.9918C20.768 11.1266 20.8438 11.3094 20.8438 11.5C20.8438 11.6906 20.768 11.8734 20.6332 12.0082C20.4984 12.143 20.3156 12.2188 20.125 12.2188H12.2188H11.8693C11.8693 12.2188 11.6444 12.2188 11.4538 12.2188C11.2632 12.2188 11.0706 12.2188 11.0706 12.2188H10.7812H2.875C2.68438 12.2188 2.50156 12.143 2.36677 12.0082C2.23198 11.8734 2.15625 11.6906 2.15625 11.5C2.15625 11.3094 2.23198 11.1266 2.36677 10.9918C2.50156 10.857 2.68438 10.7813 2.875 10.7813H10.7812Z'
    const plusPath = 'M10.7812 10.7812V2.875C10.7812 2.68438 10.857 2.50156 10.9918 2.36677C11.1266 2.23198 11.3094 2.15625 11.5 2.15625C11.6906 2.15625 11.8734 2.23198 12.0082 2.36677C12.143 2.50156 12.2188 2.68438 12.2188 2.875V10.7812H20.125C20.3156 10.7812 20.4984 10.857 20.6332 10.9918C20.768 11.1266 20.8438 11.3094 20.8438 11.5C20.8438 11.6906 20.768 11.8734 20.6332 12.0082C20.4984 12.143 20.3156 12.2188 20.125 12.2188H12.2188V20.125C12.2188 20.3156 12.143 20.4984 12.0082 20.6332C11.8734 20.768 11.6906 20.8438 11.5 20.8438C11.3094 20.8438 11.1266 20.768 10.9918 20.6332C10.857 20.4984 10.7812 20.3156 10.7812 20.125V12.2188H2.875C2.68438 12.2188 2.50156 12.143 2.36677 12.0082C2.23198 11.8734 2.15625 11.6906 2.15625 11.5C2.15625 11.3094 2.23198 11.1266 2.36677 10.9918C2.50156 10.857 2.68438 10.7812 2.875 10.7812H10.7812Z'
    const food = <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none"><path d="M5.75 9.71581V2.65625C5.75 2.46563 5.82573 2.28281 5.96052 2.14802C6.09531 2.01323 6.27813 1.9375 6.46875 1.9375C6.65937 1.9375 6.84219 2.01323 6.97698 2.14802C7.11177 2.28281 7.1875 2.46563 7.1875 2.65625V9.71581C7.60808 9.56711 7.97219 9.29163 8.22965 8.92733C8.4871 8.56303 8.62523 8.12784 8.625 7.68175V2.65625C8.625 2.46563 8.70073 2.28281 8.83552 2.14802C8.97031 2.01323 9.15313 1.9375 9.34375 1.9375C9.53437 1.9375 9.71719 2.01323 9.85198 2.14802C9.98677 2.28281 10.0625 2.46563 10.0625 2.65625V7.68175C10.0627 8.51022 9.77659 9.3133 9.25269 9.95509C8.72878 10.5969 7.99923 11.0379 7.1875 11.2036V21.3438C7.1875 21.5344 7.11177 21.7172 6.97698 21.852C6.84219 21.9868 6.65937 22.0625 6.46875 22.0625C6.27813 22.0625 6.09531 21.9868 5.96052 21.852C5.82573 21.7172 5.75 21.5344 5.75 21.3438V11.2036C4.93827 11.0379 4.20872 10.5969 3.68481 9.95509C3.16091 9.3133 2.87483 8.51022 2.875 7.68175V2.65625C2.875 2.46563 2.95073 2.28281 3.08552 2.14802C3.22031 2.01323 3.40313 1.9375 3.59375 1.9375C3.78437 1.9375 3.96719 2.01323 4.10198 2.14802C4.23677 2.28281 4.3125 2.46563 4.3125 2.65625V7.68175C4.31227 8.12784 4.4504 8.56303 4.70785 8.92733C4.96531 9.29163 5.32942 9.56711 5.75 9.71581ZM15.0938 13.3584C13.0539 12.9013 11.5 10.5366 11.5 7.6875C11.5 4.51062 13.4306 1.9375 15.8125 1.9375C18.1944 1.9375 20.125 4.51062 20.125 7.6875C20.125 10.5366 18.5711 12.9013 16.5312 13.3584V21.3438C16.5312 21.5344 16.4555 21.7172 16.3207 21.852C16.1859 21.9868 16.0031 22.0625 15.8125 22.0625C15.6219 22.0625 15.4391 21.9868 15.3043 21.852C15.1695 21.7172 15.0938 21.5344 15.0938 21.3438V13.3584ZM15.8125 12C17.296 12 18.6875 10.1456 18.6875 7.6875C18.6875 5.22937 17.296 3.375 15.8125 3.375C14.329 3.375 12.9375 5.22937 12.9375 7.6875C12.9375 10.1456 14.329 12 15.8125 12Z" fill="#D3D7A3" className="group-hover:fill-primary transition-all duration-300 ease-in-out"/></svg>
    const plus = <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none" className="lg:w-[23px] lg:h-[23px] w-[18px] h-[18px]"><path d={`${active ? minusPath : plusPath}`} fill="#101B0F" className="group-hover:fill-secondary transition-all duration-300 ease-in-out"/></svg>
    
    return(
        <div className="flex flex-row fixed top-[20px] right-[20px] z-[50]">
            <Button label={'Arrangementen'} clickHandler={()=>{navigate('/arrangementen')}} icon={food} styles={'z-[25] md:flex hidden bg-primary'}  />
            <Button active={active} label={`${active ? 'Menu' : 'Menu' }`} label2={`Sluit`} icon={plus} clickHandler={()=>{activeHandler(!active)}} fill={true} styles={'ml-[12px] z-[25]'}  />
            <div className={`w-screen h-screen fixed top-0 left-0 bg-primary z-[10] transition-all duration-500 delay-[150ms] ${active ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
                <Container styles={'h-full flex flex-col items-center justify-center'}>
                    <ul className="max-w-[780px] mx-auto">
                        {active ? menu : null}
                    </ul>
                </Container>
            </div>
        </div>
    )
}
import { graphql, useStaticQuery } from "gatsby";
import { createGlobalStyle } from "styled-components"
import React from "react";
import Helmet from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function GlobalStyles() {

  const data = useStaticQuery(graphql`
    query settingsQuery{
        wp {
        acfOptionsKleuren {
            kleuren {
            kleurpalet {
                primarycolor
                secondarycolor
            }
            }
        }
        }
        }`)

  // Palet
  const primary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.primarycolor
  const secondary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.secondarycolor

  const GlobalStyle = createGlobalStyle`

    :root {
      --primary-color: ${primary};
      --secondary-color: ${secondary};
    }
    body{
      font-family: "area-inktrap", sans-serif;
      background-color: ${primary};
      color: ${secondary};
    }
    h1{
      color: ${secondary};
      font-family: "area-inktrap", sans-serif;
      font-weight: 500;
      font-style: normal;
    }
    h2{
      color: ${secondary};
      font-family: "area-inktrap", sans-serif;
      font-weight: 600;
      font-style: normal;
    }
    h3{
      color: ${secondary};
      font-family: "area-inktrap", sans-serif;
      font-weight: 600;
      font-style: normal;
    }
    h4{
      color: ${secondary};
      font-family: "area-inktrap", sans-serif;
      font-weight: 600;
      font-style: normal;
    }
    .headerClass{
      color: ${secondary};
    }
    .footerClass{
      color: ${secondary};
    }
    .footerClass h1,  .footerClass h2,  .footerClass h3,  .footerClass h4{
      color: ${secondary};
    }
  `

  return (
    <>
      <GlobalStyle />
    </>
  )
}
import { useStaticQuery, graphql } from "gatsby"

export const useFooter= () =>{
   
    const data = useStaticQuery(graphql`
    query FooterQuery{
        wp {
            acfOptionsFooter {
                footer {
                    titel
                    adres
                    contact
                    socials {
                        facebook
                        instagram
                        linkedin
                    }
                }
            }
          }
          wpMenu(locations: {eq: EXTRA_MENU}) {
            menuItems {
              nodes {
                label
                uri
              }
            }
          }
    }
    `)

    return data
}